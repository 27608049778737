import { SERVER } from "./config"

const POST = "POST";
const GET = "GET";

export namespace Requestor {
  const PLATFORM = "applet";

  export async function request<T>(
    url: string,
    method: "GET" | "POST",
    headers?: Headers,
    body?: any
  ): Promise<{ data: T; headers: Headers }> {
    const req = new Request(url, {
      method,
      headers,
      body,
      mode: "cors",
    });

    const res = await fetch(req);

    if (res.status !== 200) {
      throw new Error(`StatusCode: ${res.status}`);
    }

    const hds = res.headers;
    const data: T = await res.json();

    return {
      data,
      headers: hds,
    };
  }

  export namespace Options {
    // 服务器返回结果
    export type Result<T> = {
      action: string;
      error: string;
      result: T;
      version: string;
    };

    // 登录参数
    export type LoginParam = {
      timestamp: number;
      payload: string;
    };
  }

  export class Factory {
    protected server = "";

    protected qrServer = "";

    protected headers = new Headers();

    constructor(server: string) {
      this.server = server;
      this.initHeaders();
    }

    private initHeaders() {
      // this.headers.set("Content-Type", "application/json");
      this.headers.set("Platform", PLATFORM);

      // 初始化上次保存的token
      const t: string | null = sessionStorage.getItem("token");
      if (t === null) {
        sessionStorage.removeItem("user");
        return;
      }

      const token: { token: string; expire: number } = JSON.parse(t);

      if (token.expire < new Date().getTime() / 1000) {
        sessionStorage.removeItem("user");
        return;
      }

      this.headers.set("Authorization", token.token);
    }

    private checkXAuthToken(header: Headers) {
      const token = header.get("X-Auth-Token");
      if (token !== null && this.headers.get("Authorization") !== token) {
        sessionStorage.setItem(
          "token",
          JSON.stringify({
            token,
            expire: Math.ceil(new Date().getTime() / 1000) + 9 * 24 * 3600,
          })
        );
        this.headers.set("Authorization", token);
      }
    }

    protected async doRequest<T>(
      url: string,
      method: "GET" | "POST",
      body?: any
    ): Promise<Requestor.Options.Result<T>> {
      const { data, headers } = await request<Requestor.Options.Result<T>>(
        url,
        method,
        this.headers,
        body
      );

      if (data.error !== "") {
        console.debug(url, method, "返回错误信息", data.error);
      }

      this.checkXAuthToken(headers);
      return data;
    }

    getToken(): string | null {
      return this.headers.get("Authorization");
    }

    // 登录
    async login<T>(
      params: Requestor.Options.LoginParam
    ): Promise<Requestor.Options.Result<T>> {
      return await this.doRequest<T>(
        `${this.server}/v1/manager/login`,
        POST,
        JSON.stringify(params)
      );
    }

    // 查询应用列表
    async getSoftwareList<T>() {
      const res = await this.doRequest<T>(
        `${this.server}/v1/app/list`,
        GET,
      );
      return res;
    }

    // 创建应用
    async createSoftware<T>(args: { name: string, remark: string }) {
      const res = await this.doRequest<T>(
        `${this.server}/v1/app/create`,
        POST,
        JSON.stringify(args)
      );
      return res;
    }

    // 查询应用版本列表
    async getSoftwareVersionList<T>(name: string) {
      const res = await this.doRequest<T>(
        `${this.server}/v1/app/versions/${name}`,
        GET,
      );
      return res;
    }

    /**
     * 上传应用新版本
     * @param args   { name: string, remark: string, version: string,file: File }
     * @returns 
     */
    async uploadSoftwareVersion<T>(args: FormData) {
      const res = await this.doRequest<T>(
        `${this.server}/v1/app/version/deploy`,
        POST,
        args
      );
      return res;
    }

    downloadUrl(name: string, version: string) {
      return `${this.server}/v1/files/${name}-${version}.exe`
    }
  }

  // 真正暴露出来的单例
  export const Instance = new Requestor.Factory(SERVER);
}

export default Requestor.Instance