import { Button, MenuProps, Space, Avatar, Layout, Menu, Dropdown, Popconfirm } from 'antd';
import { DownOutlined, LogoutOutlined } from "@ant-design/icons"
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import React from 'react';
import logo from "../assets/logo.png"
import SoftwareList from "./subs/list"
import SoftwareDetail from "./subs/detail"
import { useDispatch, useSelector } from 'react-redux';
import { getUser, loginout } from '../reducers/user.reducer';

const { Header, Footer } = Layout;

const Index: React.FC = () => {
  const User = useSelector(getUser)
  const Dispatch = useDispatch()
  const Navigate = useNavigate()

  return (
    <Layout id="components-layout-demo-top-side">
      <Header className="header" style={{ display: "flex", justifyContent: "space-between" }}>
        <Space className="text-left">
          <Avatar src={logo} />
          <span style={{ color: "rgba(255, 255, 255, 0.65)" }}>拖车侠版本管理系统</span>
          {/* <Menu theme="dark" mode="horizontal" items={items1} /> */}
        </Space>
        <div>
          {!User && <Button type="link"><Link to="/login">管理员登陆</Link></Button>}
          {User !== null && <Dropdown
            overlay={
              <Menu key="u">
                <Popconfirm
                  title="确定退出？"
                  onConfirm={() => {
                    Dispatch(loginout())
                    Navigate("/")
                  }}
                >
                  <Menu.Item key="out" icon={<LogoutOutlined />}>
                    <span>退出</span>
                  </Menu.Item>
                </Popconfirm>
              </Menu>
            }
          >
            <div>
              <span style={{ marginLeft: "8px", color: "rgba(255, 255, 255, 0.65)" }} >
                你好, {User?.username} <DownOutlined style={{ fontSize: "0.7rem" }} />
              </span>
            </div>
          </Dropdown>}
        </div>
      </Header>
      <Layout className="site-layout-background" style={{ margin: "24px" }}>
        <Routes>
          <Route path="/detail/:id" element={<SoftwareDetail />}></Route>
          <Route path="/" element={<SoftwareList />} />
        </Routes>
      </Layout>
      <Footer style={{ position: "fixed", bottom: "0px", width: "100%", textAlign: 'center' }}>贵州拖车侠科技服务有限公司</Footer>
    </Layout>
  )
};

export default Index