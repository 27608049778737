import Index from "./pages/index"
import './App.scss';
import { Provider } from "react-redux";
import store from "./reducers";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login"

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Index />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
