import { Avatar, Button, Form, Input, List, message, Modal, PageHeader, Skeleton, Spin, Upload } from "antd"
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../reducers/user.reducer";
import { UploadOutlined } from "@ant-design/icons"
import { useNavigate, useParams } from "react-router-dom";
import requestor from "../../requestor";
import { VersionType } from "../../constants/software";

const SoftwareDetail = () => {
  const [loading, setLoading] = useState<number>(0)//1列表loading 2创建loading
  const [showUpload, setShowUpload] = useState<boolean>(false)
  const [datalist, setDatalist] = useState<VersionType[]>([])
  const User = useSelector(getUser)
  const Navigate = useNavigate()

  const params = useParams()

  const getVersions = () => {
    let id = params.id;
    if (!id) return;
    setLoading(1)

    requestor.getSoftwareVersionList<VersionType[]>(id)
      .then(res => { if (res.error !== "") { throw new Error(res.error) } setDatalist(res.result.reverse()); setLoading(0) })
      .catch(e => { message.error("查询失败" + e); setLoading(0) })
  }

  const create = ({ remark, version, file }: { remark: string, version: string, file: { file: File } }) => {
    setLoading(2)
    let data = new FormData()
    console.log("值是:", params.id, file.file, remark, version)
    data.append('app', params.id!)
    data.append('remark', remark)
    data.append('version', version)
    data.append('file', file.file)
    requestor.uploadSoftwareVersion(data)
      .then(res => { if (res.error !== "") { throw new Error(res.error) } message.success('上传成功'); getVersions(); setShowUpload(false); setLoading(0) })
      .catch(e => { message.error("上传失败" + e); setLoading(0) })
  }

  useEffect(() => {
    getVersions()
  }, [params])

  const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
  ];

  const extra = useMemo(() => {
    if (!User) return []

    return [<Button type="primary" key={123} onClick={() => setShowUpload(true)}>上传版本</Button>]
  }, [User])
  return <div>
    <PageHeader title="版本列表" extra={params.id && [...extra, <Button key="re" onClick={() => getVersions()}>刷新</Button>]} onBack={() => { Navigate(-1) }}></PageHeader>

    <List
      style={{ padding: "0 16px 48px"}}
      loading={loading === 1}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={datalist}
      renderItem={(item, index) => (
        <List.Item
          key={index}
          actions={[<a key="list-loadmore-edit" target={"_blank"} href={requestor.downloadUrl(params.id!, item.version)} rel="noreferrer">下载</a>]}
        >
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
              avatar={<Avatar children={item.fileName.substring(0, 1)} />}
              title={item.fileName}
              description={item.remark}
            />{` 版本:  ${item.version}  发布时间: ${new Date(item.createdAt * 1000).toLocaleString()}`}
          </Skeleton>
        </List.Item>)} />

    <Modal
      title="上传新版本"
      open={showUpload}
      onCancel={() => setShowUpload(false)}
      maskClosable={false}
      destroyOnClose={true}
      footer={null}
    >
      <Spin spinning={loading === 2} tip="正在上传,请稍后">
        <Form layout="vertical" onFinish={create}>
          <Form.Item label="版本号" name="version" required rules={[{ required: true, message: "请填写版本号" }]}>
            <Input type="text"></Input>
          </Form.Item>
          <Form.Item label="描述信息" name="remark" required rules={[{ required: true, message: "请填写描述信息" }]}>
            <Input.TextArea></Input.TextArea>
          </Form.Item>
          <Form.Item label="文件" name="file" required rules={[{ required: true, message: "请上传文件" }]}>
            <Upload maxCount={1} multiple={false} beforeUpload={(e) => { console.log("文件", e); return false }} >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>

  </div>
}

export default SoftwareDetail