import JSEncrypt from 'jsencrypt';

export namespace RSA {
  const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4xuUo5ROvUsMxnw4EjHN
k9xCPMkk5S5rlJ3QhtNrOcBNbk0B9ByoQthFNgGO61gTCWnwS6cIShsylYDdenT/
Jeo59bn9YNZJF6xQbUyKaKQ8ObOMH2iSv+Si6LFBYg0wBl7v9iVnfTjxheoAAsCj
GHO01xRjqQ7Fq92+re4zC3GeEa8QQVq7F8JZXlr5wImiTfR0mqLTKd6His5zRpV/
5UTGs0gPLp9pujY4M2bcijPVUTCKIshmiYwsOnNHJlEXiE1bJ9+UxptBLw08MLtA
cDDDg52DTQZKR0Pp43lRJU8mU0aWM4WqcHyQPW3mwSn/S/oRwC6UfUxSFNWPso1z
2QIDAQAB
-----END PUBLIC KEY-----`;

  const crypt = new JSEncrypt({});
  crypt.setPublicKey(publicKey);

  export function encrypt(words: string): string | false {
    return crypt.encrypt(words);
  }
}
