import { Avatar, Button, Form, Input, List, message, Modal, PageHeader, Skeleton, Spin } from "antd"
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Software } from "../../constants/software";
import { getUser } from "../../reducers/user.reducer";
import requestor from "../../requestor";



const SoftwareList = () => {
  const [loading, setLoading] = useState<number>(0)//1列表loading 2创建loading
  const [isCreated, setShowCreate] = useState(false);
  const [list, setList] = useState<Software[]>([]);
  const User = useSelector(getUser)


  const getList = () => {
    setLoading(1)
    requestor.getSoftwareList<Software[]>()
      .then(res => { setList(res.result); setLoading(0) })
      .catch(() => { message.error("查询失败"); setLoading(0) })
  }

  const create = (values: { name: string, remark: string }) => {
    setLoading(2)
    requestor.createSoftware(values)
      .then(res => { message.success("创建成功"); setShowCreate(false); getList(); setLoading(0) })
      .catch(() => { message.error("创建失败"); setLoading(0) })
  }

  useEffect(() => {
    getList()
  }, [])

  const extra = useMemo(() => {
    if (!User) return []

    return [<Button type="primary" key={123} onClick={() => setShowCreate(true)}>新增软件</Button>]
  }, [User])

  return <div><PageHeader title="软件列表" extra={[...extra, <Button key="re" onClick={getList}>刷新</Button>]}></PageHeader>
    <List
      className="padding-16px"
      loading={loading === 1}
      itemLayout="horizontal"
      // loadMore={loadMore}
      dataSource={list}
      renderItem={item => (
        <List.Item
          key={item.id}
          actions={[<Button type="link" key="down-btn"><a target={"_blank"} href={requestor.downloadUrl(item.name, item.current)}>下载最新版本</a></Button>]}
        >
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
              avatar={<Avatar>{item.name}</Avatar>}
              title={<Link to={`/detail/${item.name}`}>{item.name}</Link>}
              description={item.remark}
            ></List.Item.Meta>{` 当前版本:  ${item.current}`}
          </Skeleton>
        </List.Item>)} />
    <Modal
      title="新增软件"
      open={isCreated}
      maskClosable={false}
      footer={null}
      onCancel={() => { setShowCreate(false) }}
    >
      <Spin spinning={loading === 2} tip="正在加载,请稍后">
        <Form layout="vertical" onFinish={create}>
          <Form.Item label="软件名称" name="name" required>
            <Input type="text"></Input>
          </Form.Item>
          <Form.Item label="描述信息" name="remark" required>
            <Input type="text"></Input>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">提交</Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  </div>
}

export default SoftwareList