import { Button, Form, Input, Spin } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../constants/users";
import Requestor from "../requestor";
import { RSA } from "../utils/encrypt";
import { notification } from "antd";
import { getUser, login } from "../reducers/user.reducer";



type IProps = {};

const Index: React.FC<IProps> = (props: IProps) => {
  const User = useSelector(getUser)
  const Dispatch = useDispatch()
  const navigate = useNavigate();
  const [sp, updateSp] = React.useState(User != null);

  React.useEffect(() => {
    if (User !== null) {
      notification.info({ message: "提示", description: "您已经登录" });
      navigate("/", { replace: true });
    }
  }, [User]);


  const doLogin = React.useCallback(
    async (timestamp: number, payload: string) => {
      if (sp) {
        return;
      }

      updateSp(true);
      try {
        const res = await Requestor.login<UserInfo>({
          timestamp,
          payload,
        });
        if (res.error !== "") {
          throw new Error(res.error);
        }
        notification.success({ message: "登录成功" });
        Dispatch(login(res.result))
        // props.updateLoginInfo(res.result);
      } catch (e) {
        notification.error({
          message: "用户名或密码错误",
        });
      } finally {
        updateSp(false);
      }
    },
    [props, sp, updateSp]
  );

  const onFinish = (values: { username: string; password: string }) => {
    const timestamp = Math.ceil(new Date().getTime() / 1000);
    const raw = `${timestamp}.${values.username}.${values.password}`;
    const payload = RSA.encrypt(raw);
    if (payload === false) {
      notification.error({
        message: "系统错误，请联系管理员",
      });

      return;
    }
    doLogin(timestamp, payload);
  };

  React.useEffect(() => {
    document.title = "登录-版本管理系统";
  }, []);

  React.useEffect(() => {
    if (User !== null) {
      new Notification("提示", { body: "您已经登录" });
      navigate("/");
    }
  }, [props]);

  return (
    <div className="flex flex-center flex-column height-100vh">
      <div
        className="flex flex-center flex-1 width-100p"
        style={{
          minWidth: "320px",
          backgroundImage: "linear-gradient(160deg, #1E90FF 20%, #0fb3ff 80%)",
        }}
      >
        <Spin spinning={sp}>
          <div
            className="width-320px flex flex-center flex-column padding-32px border-radius-8px"
            style={{ backgroundColor: "#fff" }}
          >
            <h2 className="margin-bottom-32px">登录-拖车侠版本管理系统</h2>
            <Form
              layout="vertical"
              name="basic"
              onFinish={onFinish}
              className="width-100p"
              autoComplete="off"
            >
              <Form.Item
                label="用户名"
                name="username"
                rules={[{ required: true, message: "请输入用户名" }]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
      <div className="height-48px text-center line-height-48px width-100p background-color-1890ff font-color-ffffff">
        贵州拖车侠科技服务有限公司 &copy; 2017-2022 &spades; renshan@2155.io
      </div>
    </div>
  );
};

export default Index;
