import { createSlice } from '@reduxjs/toolkit'
import { UserInfo } from '../constants/users'


type UserType = {
    value: UserInfo | null
    status: string
}

const initialUser: UserInfo | null = (() => {
    const item = window.sessionStorage.getItem("user");
    if (item === null) {
        return null;
    }

    const user: UserInfo = JSON.parse(item);
    return user;
})();

const initialState = {
    value: initialUser,
    status: 'idle',
}

type Login = (state: UserType, action: { payload: UserInfo }) => void
type loginoutType = (state: UserType) => void

// 创建reducer以及action
export const userSlice = createSlice<UserType, { login: Login; loginout: loginoutType }, string>({
    name: 'User',
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            if (action.payload === null) {
                window.sessionStorage.removeItem("user");
            } else {
                window.sessionStorage.setItem("user", JSON.stringify(action.payload));
            }
            state.value = { ...action.payload }
        },
        loginout: (state) => {
            sessionStorage.clear()
            state.value = null
        },
    },
})

// 查询数据
export const getUser = (state: { User: UserType }) => {
    return state.User.value
}

export const { login, loginout } = userSlice.actions
export default userSlice.reducer
